@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap");

body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f6fb;
}

:root {
  --primary-color: #1e88e5;
  --primary-hover: #0d47a1;
  --primary-dark: #073883;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-herringbone {
  background-image: url("../public/herringbone.webp");
  background-repeat: repeat;
  background-size: 150px 150px;
}

.font-roboto {
  font-family: "Roboto", sans-serif;
}

.main-page {
  display: grid;
  grid-template-columns: 0.35fr 1fr 0.35fr;
}

.blog-post {
  display: grid;
  grid-template-columns: 0.7fr 1fr;
}

.profile-img img {
  width: 100px;
  height: 134px;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 1000px) {
  .blog-post {
    display: flex;
    flex-direction: column;
  }
  .blog-post img {
    height: 150px;
  }
  .main-page {
    grid-template-columns: 1fr 1fr; /* Două coloane pentru primul și al treilea div */
    grid-template-rows: auto auto; /* Un rând pentru div-ul 2, și altul pentru div-urile 1 și 3 */
    gap: 16px;
  }

  .second-div {
    grid-column: 1 / -1; /* Ocupă ambele coloane */
    grid-row: 1; /* Primul rând */
  }

  .first-div {
    grid-column: 1 / span 1; /* Primul div ocupă prima coloană */
    grid-row: 2; /* Al doilea rând */
  }

  .third-div {
    grid-column: 2 / span 1; /* Al treilea div ocupă a doua coloană */
    grid-row: 2; /* Al doilea rând, lângă primul div */
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 992px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 1200px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}

/* Deschide dropdown-ul la hover */
.dropdown:hover .dropdown-content {
  display: block;
}

.translate-on-hover:hover {
  svg {
    transform: translateX(5px);
  }
}

.first-weather-card > div {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.second-weather-card > div {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.menu li > *:not(ul, .menu-title, details, .btn):active,
.menu li > *:not(ul, .menu-title, details, .btn).active,
.menu li > details > summary:active {
  background-color: var(--primary-dark); /* Roșu mai închis */
  color: white; /* Culoarea textului */
}

.menu li a:focus {
  color: white;
  background-color: transparent;
}

.mobile-navbar {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -100%;
  background-color: #ffffff;
  z-index: 999999;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
}

.mobile-navbar__close {
  font-size: 3rem;
  position: absolute;
  top: 3.5rem;
  right: 3.5rem;
  cursor: pointer;
  transition: all 0.3s;
}

.mobile-navbar__close:hover {
  color: #de8117;
}

.mobile-navbar__links {
  display: flex;
  flex-direction: column;
  list-style: none;
  font-size: 2.3rem;
  gap: 3rem;
  text-align: center;
}

.mobile-navbar__links li a {
  text-decoration: none;
  color: #010103;
  font-weight: 500;
  transition: all 0.3s;
}

.mobile-navbar__links li a:hover {
  color: #de8117;
}

.open-nav {
  left: 0;
}

.mobile-hamb {
  font-size: 2.8rem;
  display: none;
  cursor: pointer;
  transition: all 0.3s;
}

.mobile-hamb:hover {
  color: #de8117;
}

@media (max-width: 1000px) {
  .mobile-hamb {
    display: flex;
  }
}

.card-div:hover {
  .card-text {
    scale: 1.05;
  }
}

.top-80p {
  top: 90%;
}
